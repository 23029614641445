import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { matchValue, required } from 'services/InputFIeldsValidator/rules';
import { initDateRangeFilter } from 'store/slices/filtersSlice';

import { AuthLayout } from '../../../components';
import AppButton, { ButtonsType } from '../../../components/AppButton';
import { FormErrorsLabel } from '../../../components/FormErrorsLabel';
import InputField, { InputFieldType } from '../../../components/InputField';
import { EyeIcon, LockIcon, VisibilityOfIcon } from '../../../components/SvgComponents';
import { API_ROUTES, APP_ROUTES } from '../../../const';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { authUser, getUser } from '../../../store/slices/authSlice';
import { IUser } from '../../../types';
import { fetchWithConfig } from '../../../utils';
import styles from './styles.module.scss';

interface FormValues {
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const user = useAppSelector(getUser);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
    getValues
  } = useForm<FormValues>();

  const clearSearchParams = useCallback(() => {
    searchParams.delete('token');
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  const [showPass, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>('');
  const matchPassword = (v: string) => {
    const { password } = getValues();
    return matchValue(v, password);
  };

  const RightIcon = showPass ? VisibilityOfIcon : EyeIcon;

  const onRightIconClick = () => {
    setShow(!showPass);
  };
  const onSubmit = async (data: FormValues) => {
    if (!token) {
      setGlobalError(t('errors.unkown'));
      return;
    }
    setGlobalError('');
    try {
      setLoading(true);
      const responseData = await fetchWithConfig<{
        accessToken: string;
        user: IUser;
      }>({
        url: API_ROUTES.AUTH_RESET_PASSWORD,
        method: 'POST',
        data: {
          password: data.password,
          token
        }
      });

      dispatch(authUser({ ...responseData.user, accessToken: responseData.accessToken }));
      dispatch(initDateRangeFilter(responseData.user.timeZone));
      setLoading(false);
      clearSearchParams();
      navigate(APP_ROUTES.HOME, { replace: true });
    } catch (error: any) {
      const errorResponse = error?.response?.data?.error;

      if (Array.isArray(errorResponse?.data) || Array.isArray(error?.response?.data?.error?.data)) {
        errorResponse.data.forEach((err: any) => {
          setError(err.field, {
            type: 'manual',
            message: err.error
          });
        });
      }
      if (typeof errorResponse?.data === 'string') {
        setGlobalError(errorResponse?.data);
      }
      setLoading(false);
    }
  };

  const onInputFocus = () => {
    setGlobalError('');
  };

  useLayoutEffect(() => {
    if (user._id) {
      navigate(APP_ROUTES.HOME, { replace: true });
      return;
    }
  }, [navigate, user._id]);

  return (
    <AuthLayout
      formTitle={t('auth.forgotPassCreate')}
      formSubtitle={t('auth.forgotPassConfirm')}
      onSubmit={handleSubmit(onSubmit)}
    >
      <>
        <div>
          <InputField
            onFocus={onInputFocus}
            label={t('auth.passLabel')}
            control={control}
            defaultValue={''}
            name={'password'}
            type={showPass ? InputFieldType.text : InputFieldType.password}
            register={register}
            placeholder={t('auth.pathPlaceholder')}
            validateText={t('errors.requiredField')}
            LeftIcon={LockIcon}
            RightIcon={RightIcon}
            onRightIconClick={onRightIconClick}
            validate={{
              required
            }}
            errors={errors.password}
          />
        </div>
        <div>
          <InputField
            onFocus={onInputFocus}
            label={t('auth.confirmPass')}
            control={control}
            defaultValue={''}
            name={'confirmPassword'}
            type={showPass ? InputFieldType.text : InputFieldType.password}
            register={register}
            placeholder={t('auth.confirmPass')}
            validateText={t('errors.passwordIsnNotMatchField')}
            LeftIcon={LockIcon}
            RightIcon={RightIcon}
            onRightIconClick={onRightIconClick}
            validate={{
              required,
              matchPassword
            }}
            errors={errors.confirmPassword}
          />
        </div>
        <div className={styles.submitWrapper}>
          <AppButton
            disabled={Boolean(errors.password || globalError)}
            isLoading={loading}
            label={t('auth.savePassword')}
            btnType={ButtonsType.signIn}
          />
        </div>
        <FormErrorsLabel message={globalError} />
      </>
    </AuthLayout>
  );
};

export default ResetPassword;
