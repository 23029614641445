import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { AxiosResponse } from 'axios';
import { AlertTypes } from 'modules/Alerts/types';
import {
  IRedditCredentials,
  IStockTwitsCredentials,
  ITwitterCredentials
} from 'pages/Admin/StocksPage/CreateConnectionPopup/types';
import React from 'react';

export type SomeRequired<T, RequiredKey extends keyof T> = Required<Pick<T, RequiredKey>> & Partial<T>;

export enum RecommendedActionTypes {
  BAD_ACCOUNT = 'bad-account',
  COLLECT_DOCUMENTATION = 'collect-documentation',
  MAKE_PR = 'make-pr'
}

export interface IRecommendedItem {
  type: RecommendedActionTypes;
  text: string;
  dataSource?: Datasource;
}

export interface IConversationUser {
  _id: string;
  profileUrl: string;
  displayName: string;
  userName: string;
  dataSource: Datasource;
  isBot: boolean;
  isRobot: boolean;
}

export interface IRespondItem {
  _id: string;
  user: {
    image: string;
    displayName: string;
    userName: string;
    followers: number;
  };
  ai: Sentiment;
  text: string;
  stock: string;
  link: string;
  impression: number;
  date: string;
  dataSource: Datasource;
  reply?: {
    source: Datasource;
    stock: string;
    status: 'Waiting' | 'InProgress' | 'Success' | 'Failed';
    replyText: string;
  };
}

export interface IUser {
  _id: string;
  email: string;
  accessToken: string;
  role: ITeamRole | null;
  timeZone: string;
  teamId?: string;
  team?:
    | {
        _id: string;
        name: string;
        stockKits: { _id: string; stock: string; competitiveStocks: string[]; isMain: boolean }[];
        availablePages: ITeam['availablePages'];
        plan?: {
          _id: string;
          alias: string;
        };
      }
    | '';
  isTeamOwner: boolean;

  mailingList?: {
    alerts: boolean;
  };
}

export interface IStateUser extends IUser {
  selectedStock: { kitId?: string; stock: string; competitiveStocks: string[] };
}

export type TUiConfigItem = {
  uiKey: 'summary' | 'alerts' | 'topics' | 'charts' | 'market' | 'sentiment' | 'top';
  order: number;
  enabled: boolean;
};

export interface IUiConfig {
  config: {
    homepage: {
      general: TUiConfigItem[];
      charts: TUiConfigItem[];
    };
  };
}

export type ValuesOf<T extends any[]> = T[number];

export interface ISubscriptionPlan {
  _id: string;
  name: string;
  alias: string;
  descriptions: string[];
  price: {
    value: string;
    currency: {
      symbol: string;
      code: string;
    };
  };
}
export enum AlertStorageTypes {
  INBOX = 'inbox',
  ARCHIVED = 'archived'
}

export enum ResponderTabsTypes {
  ALL = 'all',
  STARRED = 'starred',
  REPLIED = 'replied',
  ARCHIVED = 'archived'
}

export interface IUserInList {
  dateCreated: Date;
  lastActivity: Date;
}

export enum ITeamRole {
  admin = 'admin',
  user = 'user',
  reseller = 'reseller'
}

export interface IFilters {
  dateRange: {
    type: FiltersDateRangeType;
    from: Date | string;
    to: Date | string;
    loading: boolean;
  };
  dataSource: Datasource[];
  withoutBotsAndRobots: boolean;
  competitiveActiveStocks: string[];
  postFilters: {
    tabs: {
      listCanRequest: boolean;
      value: Sentiment;
      page: number;
      count: number;
    };
  };
  usersFilters: {
    activeUser: string | null;
    tabs: {
      listCanRequest: boolean;
      value: UserFilter;
      page: number;
      count: number;
    };
  };
  alertsFilters: {
    tabs: {
      listCanRequest: boolean;
      value: AlertStorageTypes;
      page: number;
      counters: {
        [AlertStorageTypes.INBOX]: number;
        [AlertStorageTypes.ARCHIVED]: number;
      };
    };
  };
  responderFilters: {
    tabs: {
      listCanRequest: boolean;
      value: ResponderTabsTypes;
      page: number;
      counters: {
        [ResponderTabsTypes.ALL]: number;
        [ResponderTabsTypes.REPLIED]: number;
        [ResponderTabsTypes.ARCHIVED]: number;
      };
    };
  };
  isRangeOpen: boolean;
}
export type StoreFiltersKeyName = 'postFilters' | 'usersFilters' | 'alertsFilters' | 'responderFilters';

export enum FiltersDateRangeType {
  h24,
  h48,
  h72,
  w1,
  m1,
  range
}

export enum Datasource {
  Twitter = 1,
  StockTwits = 2,
  YahooFinance = 3,
  Reddit = 4,
  YouTube = 6
}

export enum Sentiment {
  neutral = 1,
  positive = 2,
  negative = 3
}

export enum UserFilter {
  followers = 'followers',
  impression = 'impression'
}

export enum UserAvaType {
  topPost,
  topUser
}

export interface ISourceUser {
  displayName: string;
  image: string;
  followers: number;
}

export interface ResponseMeta {
  page: number;
  take: number;
  hasNextPage: boolean;
  itemsCount: number;
  isInitial?: boolean;
}
export interface ResponseWithPagination<T> {
  list: T[];
  meta: ResponseMeta;
}

export interface IDataSourceResponse {
  dataSource: Datasource;
  count: number;
  counters?: Record<string, number>;
}

export interface BaseRecord {
  [key: string]: any;
}

export type TabConfig = {
  Icon?: React.ReactElement;
  name: string;
  isActive: boolean;
  toggleFn: () => void;
  count?: string;
};

export interface IResponderStore {
  activeItem: IRespondItem | null;
  repliedId: string | null;
  sort: 'impression' | 'dateCreated';
}

export interface IAlertUser {
  image: string;
  displayName: string;
  profileUrl: string;
  userName: string;
}

export interface IAlertConversation {
  _id: string;
  url: string;
  body: string;
  isResponderMark: boolean;
  stock: string;
}

export interface IAlertsStore {
  unread: number;
  filteredType: string | string[];
  hiddenAlertTypes: AlertStorageTypes | null;
  activeAlertId: string;
  isLoadingDetails: boolean;
}

export interface IAdminStore {
  team: IPaginationStore;
}

export interface IPaginationStore {
  list: {
    page: number;
  };
  details: {
    id: string;
  };
}

export interface MongoDbId {
  _id?: string;
}

interface ValidationServerError {
  field: string;
  error: string;
}

type ErrorsValidation = ValidationServerError[];

export interface ServerError extends AxiosResponse {
  response: {
    data: {
      error: {
        message: string;
        data: string | ErrorsValidation;
      };
      statusCode: number;
      message?: string;
    };
  };
}

export interface IChangeStockAgency {
  image: string;
  lastPrLink: string;
  name: string;
  symbol: string;
  totalMentions: number;
  positive: string;
  negative: string;
}

export interface IStock {
  _id: string;
  name: string;
  symbol: string;
  image: string;
  isActive: string;
  determKeywordId: number;
  yahooFinanceId: string;
  services: {
    trendingTopics: boolean;
  };
}

export enum ResponderAccountStatusEnum {
  WAITING = 'Waiting',
  VERIFIED = 'Verified',
  UNVERIFIED = 'Unverified'
}

export interface IResponderAccount {
  _id: string;
  name: string;
  source: Datasource;
  dateCreated: Date;
  status: ResponderAccountStatusEnum;
  credentials: IRedditCredentials | IStockTwitsCredentials | ITwitterCredentials;
}

export type ExecutiveSummaryDaysFilter = 'daily' | 'weekly';

export interface IResponderDisclaimer {
  _id: string;
  stock: string;
  isUsed: boolean;
  imageUri: string;
  text: string;
}

export interface IRedditSourceConfig {
  _id: string;
  stock: string;
  queries: string[];
  subredditFiltersLists: string[];
  subredditDedicatedLists: string[];
  postsDictionary: string;
}

export interface IGeneralBotsFilterConfig {
  _id: string;
  stock: string;
  phrases: string[];
  tickerCounter: number;
}

export interface IInfluencerConfig {
  _id: string;
  stock: string;
  influencerFollowersConfig: {
    [Datasource.Twitter]: number;
    [Datasource.StockTwits]: number;
    [Datasource.YahooFinance]: number;
    [Datasource.Reddit]: number;
    [Datasource.YouTube]: number;
  };
}

export enum ReportStatusEnum {
  PENDING = 'pending',
  IN_PROGRESS = 'progress',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export enum ReportScheduleTypeEnum {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly'
}

export type ReportType = 'periodic' | 'comparison' | 'bot-activity' | 'manual';

export interface IReport {
  _id: string;
  name: string;
  status: ReportStatusEnum;
  stock: string;
  link: string;
  type: ReportType;
  fromDate?: string;
  toDate?: string;
  firstPeriod?: string[];
  secondPeriod?: string[];
  archived: boolean;
  dateCreated: Date;
  mailingList: string[];
}

export interface IReportConfig {
  _id: string;
  name: string;
  stock: string;
  minPriceChangePercentage: number;
  maxEvents: number;
  scheduleType: ReportScheduleTypeEnum;
  schedule: string;
  dateCreated: Date;
  mailingList: string[];
}
export interface ITeam {
  _id: string;
  name: string;
  dateCreated: Date;
  dateUpdated: Date;
  role: ITeamRole;
  usersCount: number;
  userIds: string[];
  stockKits: { stockKitId: string; isMain: boolean }[];
  availablePages: { competitive: boolean; responder: boolean; reports: boolean };
  owner: string;
  stock: string;
}

export interface IStockKit {
  _id: string;
  name: string;
  stock: string;
  competitiveStocks: string[];
}

export interface IShortCompetitiveStock {
  name: string;
  symbol: string;
  image: string;
}

export interface ITableColumns<TItem> {
  title: string;
  dataIndex: string;
  key: string;
  render?: (item: TItem, value: any, mutate: () => void, t: (key: string) => string) => any;
  width?: number;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  sortable?: boolean;
}

export interface SideBarConfig {
  title: string;
  path: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
}

export type DatePickerFilterConfig = {
  label: string;
  type: FiltersDateRangeType;
  isActive: boolean;
  onClick: () => void;
  onClose?: () => void;
};
