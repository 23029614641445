import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CrudActions, CrudTable, Title, TitleType } from '../../../../components';
import Link, { LinkType } from '../../../../components/Link';
import { API_ROUTES, APP_ROUTES } from '../../../../const';
import { IStockKit, ITableColumns } from '../../../../types';
import DashboardLayout from '../../DashboardLayout';

const rows: ITableColumns<IStockKit>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Date created',
    dataIndex: 'dateCreated',
    key: 'dateCreated',
    sortable: true,
    render: (item, value) => {
      return <span>{DateTime.fromJSDate(new Date(value)).toFormat('LLL dd, yyyy')}</span>;
    }
  },
  {
    title: 'Stock',
    dataIndex: 'stock',
    key: 'stock'
  },
  {
    title: 'Competitive Stocks',
    dataIndex: 'competitiveStocks',
    key: 'competitiveStocks',
    render: (item, value) => {
      return value.join(', ');
    },
    align: 'center'
  },
  {
    title: '',
    dataIndex: '',
    key: '_id',
    render: (item, value, refresh = () => {}) => {
      return (
        <CrudActions
          toEdit={`${APP_ROUTES.STOCK_KITS_EDIT}/${value}`}
          onRemoveUrl={`${API_ROUTES.STOCK_KITS}/${value}`}
          dialogTitle={
            <div>
              <Title type={TitleType.h3}>
                <>Confirm delete Stock Kit.</>
              </Title>
              <Title type={TitleType.h5}>
                <> Stock kit: {item.name}</>
              </Title>
            </div>
          }
          refresh={refresh}
        />
      );
    }
  }
];

export const StockKitsList = () => {
  const { t } = useTranslation();
  return (
    <DashboardLayout
      title={t('pages.admin.stockKits')}
      createLink={<Link to={APP_ROUTES.STOCK_KITS_CREATE} label={'create'} linkType={LinkType.simple} />}
    >
      <>
        <CrudTable<IStockKit> columns={rows} requestUrl={API_ROUTES.STOCK_KITS} />
      </>
    </DashboardLayout>
  );
};
