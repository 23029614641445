import { ChooseCompetitiveStocks, CustomSelect } from 'components';
import useOptionStocks from 'hooks/useOptionStocks';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { required } from 'services/InputFIeldsValidator/rules';
import { IStock } from 'types';

import AppButton, { ButtonsType } from '../../../../components/AppButton';
import InputField, { InputFieldType } from '../../../../components/InputField';
import { API_ROUTES, APP_ROUTES } from '../../../../const';
import InputFieldsValidator from '../../../../services/InputFIeldsValidator';
import ToastifyService from '../../../../services/ToastifyService';
import { catchRequestErrors, fetchWithConfig } from '../../../../utils';
import ContentBlock from '../../ContentBlock';
import DashboardLayout from '../../DashboardLayout';

type FormValues = {
  name: string;
  stock: string;
  competitiveStocks: string[];
};

export const CreateStockKit = () => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    watch,
    control,
    reset
  } = useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { stocksOptionLoading, stocksOptionData } = useOptionStocks();
  const selectedMainStock = watch('stock');

  const onChangeCompetitiveStocks = (stocks: string[]) => {
    setValue('competitiveStocks', stocks);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      setLoading(true);
      const responseData = await fetchWithConfig({
        url: API_ROUTES.STOCK_KITS,
        method: 'POST',
        data
      });

      ToastifyService.setToast('Stock Kit created succesfuly ', 'success');
      reset();
      setLoading(false);
      navigate(APP_ROUTES.STOCK_KITS);
    } catch (error: any) {
      catchRequestErrors(error, setError, () => {}, setLoading);
    }
  };

  return (
    <DashboardLayout
      title={t('pages.admin.stockKitCreate')}
      onSubmit={handleSubmit(onSubmit)}
      actionBtn={
        <AppButton
          disabled={!!Object.keys(errors).length}
          isLoading={loading}
          label={'create'}
          btnType={ButtonsType.signIn}
        />
      }
    >
      <>
        <ContentBlock>
          <InputField
            label={'Enter name'}
            control={control}
            defaultValue={''}
            name={'name'}
            type={InputFieldType.text}
            register={register}
            placeholder={'Stock Kit name'}
            validateText={t('errors.requiredField')}
            validate={{
              required
            }}
            errors={errors.name}
          />
        </ContentBlock>
        <ContentBlock>
          <CustomSelect<IStock>
            data={stocksOptionData!}
            placeholder={'Choose stock'}
            loading={stocksOptionLoading}
            name={'stock'}
            control={control}
            defaultValue={''}
            register={register}
            errors={errors.stock}
          />
        </ContentBlock>
        <ContentBlock>
          <ChooseCompetitiveStocks mainStock={selectedMainStock} onChange={onChangeCompetitiveStocks} />
        </ContentBlock>
      </>
    </DashboardLayout>
  );
};
