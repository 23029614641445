import { Button, CircularProgress, Link, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { theme } from 'theme';

import styles from './styles.module.scss';

export enum ButtonsType {
  simple,
  signIn,
  simpleSave,
  outlined,
  borderedTransparent,
  borderedFilled,
  borderedFilledReverse,
  borderedTransparentLowBorder
}

export type ButtonProp = {
  btnType: ButtonsType;
  disabled?: boolean;
  isLoading?: boolean;
  label?: string | React.ReactElement;
  link?: string;
  customStyles?: Record<string, any>;
  onClick?: () => void;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | React.JSXElementConstructor<any>;
  htmlType?: 'submit' | 'button';
  isInputFile?: boolean;
  onFileChange?: (file: File) => void;
  accept?: string;
};

const buttonStyles: Record<string, any> = {
  [ButtonsType.signIn]: { width: '100%', minHeight: 44 },
  [ButtonsType.simpleSave]: { width: 'auto', padding: '7px 14px', minHeight: 40, textTransform: 'capitalize' },
  [ButtonsType.simple]: {
    width: 'auto',
    background: 'transparent',
    textTransform: 'capitalize'
  },
  [ButtonsType.borderedTransparent]: {
    border: '1px solid transparent',
    borderColor: theme.palette.grey[500],
    padding: '7px 20px',
    background: 'transparent',
    borderRadius: '45px',
    color: '#fff',
    '&:hover': {
      background: 'transparent'
    }
  },
  [ButtonsType.borderedFilled]: {
    border: '1px solid transparent',
    borderColor: '#fff',
    padding: '7px 20px',
    background: '#fff',
    color: '#0C111D',
    borderRadius: '45px',
    '&:hover': {
      background: '#fff'
    }
  },
  [ButtonsType.borderedFilledReverse]: {
    border: '1px solid transparent',
    borderColor: '#fff',
    padding: '7px 20px',
    background: '#0C111D',
    color: '#fff',
    borderRadius: '45px',
    '&:hover': {
      background: '#0C111D'
    }
  },
  [ButtonsType.borderedTransparentLowBorder]: {
    border: '1px solid transparent',
    borderColor: theme.palette.grey[500],
    padding: '7px 20px',
    background: 'transparent',
    color: '#fff',
    '&:hover': {
      background: 'transparent'
    }
  },
  default: {
    fontSize: 16,
    lineHeight: '28px',
    textTransform: 'initial'
  }
};
export const AppButton: React.FC<ButtonProp> = ({
  btnType,
  disabled,
  isLoading,
  label,
  customStyles = {},
  onClick = () => {},
  link,
  Icon,
  htmlType = 'submit',
  isInputFile,
  onFileChange = () => {},
  accept = 'image/jpeg, image/png'
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  if (link) {
    return (
      <Link href={link} target="_blank" sx={{ ...buttonStyles.default, ...buttonStyles[btnType], ...customStyles }}>
        {!!label && label}
      </Link>
    );
  }

  const onButtonClick = () => {
    if (isLoading || disabled) {
      return;
    }

    if (isInputFile) {
      inputFileRef.current?.click();
    }

    onClick();
  };

  return (
    <Button
      onClick={onButtonClick}
      sx={{ ...buttonStyles.default, ...buttonStyles[btnType], ...customStyles }}
      type={isInputFile ? 'button' : htmlType}
      variant={btnType === ButtonsType.simple ? 'text' : 'contained'}
      color="steelBlue"
      {...((disabled || isLoading) && { disabled: true })}
    >
      {isLoading && <CircularProgress className={styles.loaderBar} size={28} />}
      <span className={classNames({ [styles.loadingHiddenText]: isLoading })}>
        {!!Icon && <Icon />} {!!label && label}
      </span>
      {isInputFile && (
        <input
          ref={inputFileRef}
          className={styles.inputFile}
          type="file"
          accept={accept}
          onChange={(e: any) => onFileChange(e.target.files[0])}
        />
      )}
    </Button>
  );
};

export default AppButton;
