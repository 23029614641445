import { Chip, IconButton, Stack, Tooltip } from '@mui/material';
import AppButton, { ButtonsType } from 'components/AppButton';
import { CustomSelect } from 'components/CustomSelect';
import { API_ROUTES } from 'const';
import useTimeZonesOptions from 'hooks/useTimeZonesOptions';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ToastifyService from 'services/ToastifyService';
import { fetchWithConfig } from 'utils';

import InputField, { InputFieldType } from '../../../../components/InputField';
import { HelpIcon } from '../../../../components/SvgComponents';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { required } from '../../../../services/InputFIeldsValidator/rules';
import { getUser, setSelectedTimeZone, updateUser } from '../../../../store/slices/authSlice';
import ContentBlock from '../../ContentBlock';
import ProfileLayout from '../../ProfileLayout';
import FormGroup from '../../components/FormGroup';
import PasswordForm from './PasswordForm';
import styles from './styles.module.scss';

type FormValues = {
  currentPassword: string;
  newPassword: string;
  timeZone: string;
};

const sitePrefix = 'https://';
export const ProfilePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control
  } = useForm<FormValues>();
  const user = useAppSelector(getUser);
  const timeZones = useTimeZonesOptions();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const onSubmit = async (data: FormValues) => {
    try {
      setLoading(true);
      const responseData = await fetchWithConfig<{
        message: string;
      }>({
        url: API_ROUTES.UPDATE_USER,
        method: 'PATCH',
        data: {
          timeZone: data.timeZone
        }
      });
      ToastifyService.setToast(responseData.message, 'success');
      setLoading(false);
      dispatch(setSelectedTimeZone(data.timeZone));
      dispatch(updateUser({ timeZone: data.timeZone }));
    } catch (error: any) {
      setLoading(false);
    }
  };

  const UserNamePopover = useCallback(() => {
    return (
      <Tooltip title={t('pages.tabs.labels.userEmailPopover')} placement="top">
        <IconButton className={styles.iconBtn}>
          <HelpIcon />
        </IconButton>
      </Tooltip>
    );
  }, [t]);

  return (
    <ProfileLayout>
      <FormGroup
        onSubmit={handleSubmit(onSubmit)}
        title={t('pages.profile.profile')}
        subtitle={t('pages.profile.profileSubtitle')}
        submitLink={
          <AppButton
            btnType={ButtonsType.simpleSave}
            label={t('buttons.save')}
            isLoading={loading}
            disabled={loading}
          />
        }
      >
        <div>
          <ContentBlock>
            <div className={styles.row}>
              <div className={styles.labelWrapper}>
                <p className={styles.label}>{t('pages.tabs.labels.userEmail')}</p>
              </div>
              <div className={styles.labelRight}>
                <InputField
                  disabled={true}
                  label={''}
                  control={control}
                  defaultValue={user.email}
                  name={'userName'}
                  type={InputFieldType.text}
                  register={register}
                  placeholder={t('pages.tabs.labels.userEmail')}
                  validateText={t('errors.requiredField')}
                  RightIcon={UserNamePopover}
                  validate={{
                    required
                  }}
                  errors={errors.newPassword}
                />
              </div>
            </div>
          </ContentBlock>
          <ContentBlock>
            <div className={styles.row}>
              <div className={styles.labelWrapper}>
                <p className={styles.label}>{t('pages.tabs.labels.timeZone')}</p>
              </div>
              <div className={styles.labelRight}>
                <CustomSelect
                  withSearch
                  data={timeZones}
                  loading={false}
                  name="timeZone"
                  isMultiple={false}
                  defaultValue={user.timeZone}
                  control={control}
                  register={register}
                />
              </div>
            </div>
          </ContentBlock>
          {user.team && (
            <ContentBlock>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>{t('pages.tabs.labels.availableStock')}</p>
                </div>
                <div className={styles.labelRight}>
                  <Stack
                    sx={{ flexWrap: 'wrap', rowGap: '10px', justifyContent: 'center' }}
                    direction="row"
                    spacing={1}
                  >
                    {user.team.stockKits.map(({ stock }) => (
                      <Chip label={stock} key={stock} size={'small'} />
                    ))}
                  </Stack>
                </div>
              </div>
            </ContentBlock>
          )}
          <ContentBlock>
            <div className={styles.row}>
              <div className={styles.labelWrapper}>
                <p className={styles.label}>{t('pages.profile.team')}</p>
              </div>
              <div className={styles.labelRight}>
                <Stack direction="row" spacing={1}>
                  <Chip label={user?.team ? user.team?.name : '-'} size={'small'} />
                </Stack>
              </div>
            </div>
          </ContentBlock>
          <PasswordForm />
        </div>
      </FormGroup>
    </ProfileLayout>
  );
};
