import { useAppSelector } from 'hooks';
import React from 'react';

import { SidebarNav, TitleType } from '../../../components';
import { Layout, Title } from '../../../components';
import { APP_ROUTES } from '../../../const';
import { getUser } from '../../../store/slices/authSlice';
import styles from './styles.module.scss';

const config = [
  {
    title: 'pages.profile.profile',
    path: APP_ROUTES.PROFILE
  }
];
const ProfileLayout: React.FC<{
  children: React.ReactNode;
  title?: string;
}> = ({ children, title }) => {
  const user = useAppSelector(getUser);
  const updatedConfig = [...config];

  /*if (user.isTeamOwner) {
    updatedConfig.push({
      title: 'pages.profile.plans',
      path: APP_ROUTS.PLANS
    });
  }*/

  return (
    <Layout>
      <div className={styles.wrapper}>
        <SidebarNav config={updatedConfig} />
        <div className={styles.flexCol}>
          {title && (
            <div className={styles.header}>
              <Title type={TitleType.h2}>{title}</Title>
            </div>
          )}
          {children}
        </div>
      </div>
    </Layout>
  );
};

export default ProfileLayout;
