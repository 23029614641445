export type GaugeSentimentKey = 'low' | 'medium' | 'high' | 'critical';

type GaugeSentiment = Record<
  GaugeSentimentKey,
  {
    label: string;
    min: number;
    max: number;
  }
>;

export const GAUGE_SENTIMENT_CONFIG: GaugeSentiment = {
  low: {
    label: 'Low',
    min: 0,
    max: 50
  },
  medium: {
    label: 'Medium',
    min: 50,
    max: 70
  },
  high: {
    label: 'High',
    min: 70,
    max: 90
  },
  critical: {
    label: 'Critical',
    min: 90,
    max: 100
  }
};
