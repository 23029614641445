import { Grid } from '@mui/material';
import classNames from 'classnames';
import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig';
import React from 'react';

// @ts-ignore
import AppLogo, { AppLogoSize } from '../../AppLogo';
import MuiBoxContainer from '../../MuiBoxContainer';
import FormHeading from '../FormHeading';
import styles from './styles.module.scss';

type AuthLayoutProps = {
  children: React.ReactNode;
  formTitle: string;
  formSubtitle?: string;
  onSubmit?: any;
};
export const AuthLayout: React.FC<AuthLayoutProps> = ({ children, formSubtitle = '', formTitle, onSubmit }) => {
  const Tag = !!onSubmit && typeof onSubmit === 'function' ? 'form' : 'div';
  const props = !!onSubmit && typeof onSubmit === 'function' ? { onSubmit } : {};
  const { loginImage } = useWhiteLabelConfig();
  const isVideo = loginImage.includes('mp4');

  return (
    <div className={styles.root}>
      <MuiBoxContainer nativeClassNames={styles.mainWrapper}>
        <>
          <Grid item xs={6}>
            <div className={styles.container}>
              <MuiBoxContainer>
                <AppLogo size={AppLogoSize.medium} />
              </MuiBoxContainer>
              <MuiBoxContainer nativeClassNames={styles.formWrapper}>
                <Grid xs={12} item>
                  <div>
                    <FormHeading title={formTitle} subtitle={formSubtitle} />
                    <Tag {...props} className={styles.gap}>
                      {children}
                    </Tag>
                  </div>
                </Grid>
              </MuiBoxContainer>
            </div>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex' }} className={styles.imageGrid}>
            <div className={classNames({ [styles.image]: !isVideo, [styles.video]: isVideo })}>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              {isVideo ? (
                <video autoPlay loop muted>
                  <source src={loginImage} type="video/mp4" />
                </video>
              ) : (
                <img src={loginImage} alt="" />
              )}
            </div>
          </Grid>
        </>
      </MuiBoxContainer>
    </div>
  );
};
