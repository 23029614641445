import { AppButton, ButtonsType, CustomSelect } from 'components';
import InputField, { InputFieldType } from 'components/InputField';
import { API_ROUTES } from 'const';
import { useAppSelector } from 'hooks';
import useTeams from 'hooks/useTeams';
import { DateTime } from 'luxon';
import ContentBlock from 'pages/Admin/ContentBlock';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { required } from 'services/InputFIeldsValidator/rules';
import { getSelectedTimeZone } from 'store/slices/authSlice';
import useSWR from 'swr';
import { ITeam, IUser } from 'types';
import { fetchWithConfig } from 'utils';
import { getTimeFrame } from 'utils';

import styles from '../styles.module.scss';

const formatDate = (date: string) => {
  return new Date(date.slice(0, -6));
};

interface IInfoFormProps {
  control: any;
  register: any;
  errors: any;
  watch: any;
  setValue: any;
  setError: any;
  clearErrors: any;
}
const ManualForm: React.FC<IInfoFormProps> = ({
  control,
  register,
  errors,
  setValue,
  watch,
  setError,
  clearErrors
}) => {
  const { t } = useTranslation();
  const pdfFile = watch('pdfFile');
  const handleFileChange = (file: File) => {
    if (file && file.type === 'application/pdf') {
      setValue('pdfFile', file);
    }
    if (file) {
      clearErrors();
    }
  };
  const selectedFrom = watch('fromDate');
  const selectedTo = watch('toDate');
  const minDate = DateTime.now().startOf('month').minus({ month: 3 }).toJSDate();
  const zone = useAppSelector(getSelectedTimeZone);
  const initialRange = [
    formatDate(selectedFrom || DateTime.now().minus({ days: 1 }).toJSDate().toString()),
    formatDate(selectedTo || DateTime.now().toJSDate().toString())
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const { teamsData, teamsLoading } = useTeams();
  const { userId } = useParams();
  const { data, isLoading } = useSWR<IUser>(userId ? `${API_ROUTES.USERS}/${userId}` : null, (url): Promise<IUser> => {
    return fetchWithConfig({
      url
    });
  });

  const onRangeChange = (fromDate: string, toDate: string) => {
    setValue('timeFrame', getTimeFrame(fromDate, toDate, zone));
    setValue('fromDate', fromDate);
    setValue('toDate', toDate);
    setError('timeFrame', null);
  };
  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={styles.field}>
          <InputField
            label={t('pages.reports.modal.fields.reportName')}
            control={control}
            defaultValue=""
            name="name"
            type={InputFieldType.text}
            register={register}
            placeholder=""
            validate={{
              required
            }}
            errors={errors.name}
          />
        </div>
        <div className={styles.field}>
          <InputField
            autocomplete={false}
            rangePickerConfig={{ minDate, initialRange, onRangeChange }}
            label={t('pages.reports.modal.fields.timeFrame')}
            control={control}
            register={register}
            name="timeFrame"
            defaultValue=""
            errors={errors.timeFrame}
            placeholder=""
            type={InputFieldType.text}
            validate={{ required }}
          />
        </div>

        <div className={styles.field}>
          <ContentBlock>
            <div className={styles.label}>{t('pages.reports.modal.fields.teamSelect')}</div>
            <div className={styles.row}>
              <CustomSelect<Partial<ITeam>>
                key={new Date().getMilliseconds().toString()}
                data={teamsData! || []}
                placeholder={''}
                loading={isLoading}
                name={'teamId'}
                required
                control={control}
                isMultiple={false}
                register={register}
                defaultValue={data?.teamId || ''}
                errors={errors.teamId}
              />
            </div>
          </ContentBlock>
        </div>
        <AppButton
          isLoading={loading}
          label={t('pages.reports.modal.fields.uploadPdf')}
          btnType={ButtonsType.simple}
          isInputFile={true}
          onFileChange={handleFileChange}
          accept="application/pdf"
        />
        {pdfFile && <span style={{ marginLeft: '10px', fontStyle: 'italic' }}>{pdfFile.name}</span>}
        {errors.pdfFile && <span className={styles.error}>{errors.pdfFile.message}</span>}
      </form>
    </>
  );
};

export default ManualForm;
