import { Skeleton, Typography } from '@mui/material';
import classNames from 'classnames';
import { Title, TitleType } from 'components';
import useCompetitiveStocks from 'hooks/useCompetitiveStocks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from 'utils';

import { IStockMention } from '../index';
import styles from './styles.module.scss';

interface IMentionsListProps {
  data?: IStockMention[];
  loading: boolean;
}

const MentionsList: React.FC<IMentionsListProps> = ({ data, loading }) => {
  const { competitiveStocks, mainStock } = useCompetitiveStocks();
  const { t } = useTranslation();

  if (!data || loading || !competitiveStocks) {
    return (
      <div className={classNames(styles.listWrapper, styles.noMargin)}>
        <Skeleton variant="rounded" height={38} width="100%" />
        {Array.from(Array(4)).map((item, index) => {
          return (
            <div className={styles.skeletonWrapper} key={index}>
              <Skeleton variant="rounded" height={46} width={46} />
              <Skeleton variant="text" height={46} width="100%" />
            </div>
          );
        })}
      </div>
    );
  }

  const sortedData = [...data].sort((x, y) => {
    if (x.stock === mainStock.symbol) return -1;
    if (y.stock === mainStock.symbol) return 1;

    return y.count - x.count;
  });

  return (
    <>
      <div className={styles.listWrapper}>
        <div className={styles.listHeadWrapper}>
          <div className={styles.firstCol}></div>
          <div className={styles.col}>{t('pages.competitiveAnalysis.mentionsTitle')}</div>
          <div className={styles.col}>{t('pages.competitiveAnalysis.botsTitle')}</div>
        </div>
        <div className={styles.listDataWrapper}>
          {sortedData.map(({ stock, count, botsPercentage }) => {
            const stockData = [...competitiveStocks, mainStock].find(({ symbol }) => symbol === stock);

            if (!stockData) {
              return null;
            }

            const isMainStock = stock === mainStock.symbol;

            const { image, symbol } = stockData;

            return (
              <div className={classNames(styles.listRowData, { [styles.main]: isMainStock })} key={stock}>
                <div className={styles.firstCol}>
                  <div className={styles.wrapper}>
                    <div className={styles.imageWrapper}>
                      <img width={24} height={24} src={image} alt="" />
                    </div>
                    <div className={styles.titleWrapper}>
                      <Title type={TitleType.h2}>{symbol}</Title>
                      {isMainStock && (
                        <Typography className={styles.mainStockText} variant="body2">
                          {t('pages.competitiveAnalysis.yourStock')}
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.col}>
                  <Typography className={styles.count}>{numberWithCommas(count)}</Typography>
                </div>
                <div className={styles.col}>
                  <Typography className={styles.count}>
                    {botsPercentage === null ? '---' : `${botsPercentage}%`}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MentionsList;
