import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { mailRule, required } from 'services/InputFIeldsValidator/rules';

import { AuthLayout } from '../../../components';
import AppButton, { ButtonsType } from '../../../components/AppButton';
import { FormErrorsLabel } from '../../../components/FormErrorsLabel';
import InputField, { InputFieldType } from '../../../components/InputField';
import StyledLink, { LinkType } from '../../../components/Link';
import { MailIcon } from '../../../components/SvgComponents';
import { API_ROUTES, APP_ROUTES } from '../../../const';
import { useAppSelector } from '../../../hooks';
import toastifyService from '../../../services/ToastifyService';
import { getUser } from '../../../store/slices/authSlice';
import { fetchWithConfig } from '../../../utils';
import styles from './styles.module.scss';

interface FormValues {
  email: string;
}

const ForgotPassword = () => {
  const user = useAppSelector(getUser);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
    getValues
  } = useForm<FormValues>();

  const clearSearchParams = useCallback(() => {
    searchParams.delete('token');
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  const [loading, setLoading] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>('');

  const onSubmit = async (data: FormValues) => {
    setGlobalError('');
    try {
      setLoading(true);
      const responseData = await fetchWithConfig<{
        message: string;
      }>({
        url: API_ROUTES.FORGOT_PASS,
        method: 'POST',
        data: {
          email: data.email
        }
      });
      setLoading(false);
      clearSearchParams();
      toastifyService.setToast(responseData?.message || t('pages.forgotPass.label'), 'success');
    } catch (error: any) {
      const errorResponse = error?.response?.data?.error;

      if (Array.isArray(errorResponse?.data) || Array.isArray(error?.response?.data?.error?.data)) {
        errorResponse.data.forEach((err: any) => {
          setError(err.field, {
            type: 'manual',
            message: err.error
          });
        });
      }
      if (typeof errorResponse?.data === 'string') {
        setGlobalError(errorResponse?.data);
      }
      setLoading(false);
    }
  };

  const onInputFocus = () => {
    setGlobalError('');
  };

  useLayoutEffect(() => {
    if (user._id) {
      navigate(APP_ROUTES.HOME, { replace: true });
      return;
    }
  }, [navigate, user._id]);

  return (
    <AuthLayout
      formSubtitle={t('auth.forgotPassLabel')}
      formTitle={t('auth.forgotPass')}
      onSubmit={handleSubmit(onSubmit)}
    >
      <>
        <div className={styles.formWrapper}>
          <InputField
            onFocus={onInputFocus}
            label={t('auth.emailLabel')}
            control={control}
            defaultValue={''}
            name={'email'}
            type={InputFieldType.email}
            register={register}
            placeholder={t('auth.emailLabel')}
            validateText={t('errors.requiredField')}
            LeftIcon={MailIcon}
            validate={{
              mailRule,
              required
            }}
            errors={errors.email}
          />
        </div>

        <AppButton
          disabled={Boolean(errors.email || globalError)}
          isLoading={loading}
          label={t('auth.forgotPassRequest')}
          btnType={ButtonsType.signIn}
        />
        <StyledLink
          to={APP_ROUTES.LOGIN}
          label={t('auth.backToSignIn')}
          linkType={LinkType.simple}
          customStyles={styles.backLink}
        />
        {!!globalError && <FormErrorsLabel message={globalError} />}
      </>
    </AuthLayout>
  );
};

export default ForgotPassword;
