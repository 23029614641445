import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

export const ScrollWrapper: React.FC<{ children: React.ReactNode; size?: number; className?: string }> = ({
  children,
  size = 0,
  className
}) => {
  return (
    <div className={classNames(styles.wrapper, className)} {...(!!size && { style: { maxHeight: size } })}>
      {children}
    </div>
  );
};
