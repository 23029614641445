import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CrudActions, CrudTable, EllipsisWithTooltip, Title, TitleType } from '../../../../components';
import Link, { LinkType } from '../../../../components/Link';
import { API_ROUTES, APP_ROUTES } from '../../../../const';
import { ITableColumns, ITeam } from '../../../../types';
import DashboardLayout from '../../DashboardLayout';

const rows: ITableColumns<ITeam>[] = [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
    render: (item, value) => <EllipsisWithTooltip text={value} width={60} />,
    width: 100
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sortable: true
  },
  {
    title: 'Date created',
    dataIndex: 'dateCreated',
    key: 'dateCreated',
    sortable: true,
    render: (item, value) => {
      return <span>{DateTime.fromJSDate(new Date(value)).toFormat('LLL dd, yyyy')}</span>;
    }
  },
  {
    title: 'Last updated',
    dataIndex: 'dateUpdated',
    key: 'dateUpdated',
    sortable: true,
    render: (item, value) => {
      return <span>{DateTime.fromJSDate(new Date(value)).toFormat('LLL dd, yyyy')}</span>;
    }
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role'
  },
  {
    title: 'Users in team',
    dataIndex: 'usersCount',
    key: 'usersCount',
    align: 'center',
    sortable: true
  },
  {
    title: '',
    dataIndex: '',
    key: '_id',
    render: (item, value, refresh = () => {}) => {
      return (
        <CrudActions
          toDetails={`${APP_ROUTES.TEAMS}/${value}`}
          toEdit={`${APP_ROUTES.TEAMS_EDIT}/${value}`}
          onRemoveUrl={`${API_ROUTES.TEAMS}/${value}`}
          dialogTitle={
            <div>
              <Title type={TitleType.h3}>
                <>Confirm delete team.</>
              </Title>
              <Title type={TitleType.h4}>
                <>Team ID: {value}</>
              </Title>
              <Title type={TitleType.h5}>
                <> Team name: {item.name}</>
              </Title>
            </div>
          }
          refresh={refresh}
        />
      );
    }
  }
];

export const TeamsList = () => {
  const { t } = useTranslation();

  return (
    <DashboardLayout
      title={t('pages.admin.teams')}
      createLink={<Link to={APP_ROUTES.TEAMS_CREATE} label={'create'} linkType={LinkType.simple} />}
    >
      <>
        <CrudTable<ITeam> columns={rows} requestUrl={API_ROUTES.TEAMS} />
      </>
    </DashboardLayout>
  );
};
