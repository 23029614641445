import { Grid } from '@mui/material';
import classNames from 'classnames';
import { CompanyInfo, Layout, SectionCard, SectionCardHeading } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ExecutiveSummary, StockNegativePositiveTopics } from 'modules';
import { DateFilter } from 'modules';
import CompetitiveMentions from 'pages/CompetitiveAnalysis/components/Mentions';
import CompetitiveSentiments from 'pages/CompetitiveAnalysis/components/Sentiments';
import WithoutBotsAndRobots from 'pages/HomePage/StickyFilters/WithoutBotsAndRobots';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedTimeZone, getUserCompetitiveStocks } from 'store/slices/authSlice';
import { setDateRangeFilter } from 'store/slices/filtersSlice';
import { DatePickerFilterConfig, ExecutiveSummaryDaysFilter, FiltersDateRangeType } from 'types';
import { getStaticTimeRange } from 'utils';

import styles from './styles.module.scss';

interface IUpdateAllFilters {
  (filter: ExecutiveSummaryDaysFilter): void;
}

const CeoMatrix: React.FC<{ blurContent?: boolean }> = ({ blurContent }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedFilter, setSelectedFilter] = useState<ExecutiveSummaryDaysFilter>('daily');
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);
  const competititveStocks = useAppSelector(getUserCompetitiveStocks);

  const updateAllFilters: IUpdateAllFilters = useCallback(
    (filter) => {
      setSelectedFilter(filter);
      let filterType: FiltersDateRangeType = FiltersDateRangeType.h24;

      filterType = filter === 'daily' ? FiltersDateRangeType.h24 : FiltersDateRangeType.w1;

      const result = getStaticTimeRange(filterType, selectedTimeZone);
      dispatch(setDateRangeFilter({ type: filterType, ...result }));
    },
    [selectedTimeZone, dispatch]
  );

  const filterConfig: DatePickerFilterConfig[] = useMemo(
    () => [
      {
        label: '24h',
        type: FiltersDateRangeType.h24,
        isActive: selectedFilter === 'daily',
        onClick: () => {
          updateAllFilters('daily');
        }
      },
      {
        label: '1 week',
        type: FiltersDateRangeType.w1,
        isActive: selectedFilter === 'weekly',
        onClick: () => {
          updateAllFilters('weekly');
        }
      }
    ],
    [selectedFilter, updateAllFilters]
  );

  return (
    <Layout blurContent={blurContent}>
      <div className={classNames(styles.wrapper, styles.topWrapper)}>
        <div className={styles.stockTitle}>
          <CompanyInfo />
          <div className={styles.filterWrapper}>
            <DateFilter filterConfig={filterConfig} forceLoading={false} />
          </div>
        </div>
        <ExecutiveSummary filterDay={selectedFilter} />
        <StockNegativePositiveTopics />
        {competititveStocks?.length > 0 && (
          <SectionCard noBorder>
            <div className={styles.competitiveHeader}>
              <div>
                <SectionCardHeading title={t('mainNavigation.competitive')} />
              </div>
              <div>
                <WithoutBotsAndRobots />
              </div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CompetitiveMentions />
              </Grid>
              <Grid item xs={12} md={6}>
                <CompetitiveSentiments />
              </Grid>
            </Grid>
          </SectionCard>
        )}
      </div>
    </Layout>
  );
};
export default CeoMatrix;
