import Container from '@mui/material/Container';
import classNames from 'classnames';
import Header from 'components/Layout/Header';
import React from 'react';

import styles from './styles.module.scss';

export const Layout: React.FC<{
  children: React.ReactNode;
  showHeader?: boolean;
  blurContent?: boolean;
}> = ({ children, showHeader = true, blurContent = false }) => {
  return (
    <div className={styles.wrapper}>
      {showHeader && <Header />}
      <Container className={classNames({ [styles.blurContent]: blurContent })}>{children}</Container>
    </div>
  );
};
